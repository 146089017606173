import { DefaultTheme } from 'styled-components';
import base from './base';

const antTheme: DefaultTheme = {
  ...base,
  colors: {
    primary: '#0F370A',
    secondary: 'magenta',
    textPrimary: '#FFFBD8',
    borderPrimary: '#0f370a',
  },
};

export default antTheme;
