import React, { FC, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button as BaseButton, Flex, Image, Link as BaseLink, ExternalLink } from '../Toolkit';
import useMatchBreakpoints from '../../hooks/useMatchBreakpoints';
import { useIsMobile, useIsTablet } from '../../hooks/useDevice';
import { connect } from '../../redux/actions';
import { RootState } from '../../redux/types';

import Logo from '../../assets/images/logo.png';
import Discord from '../../assets/images/discord.png';
import Twitter from '../../assets/images/twitter.png';
import Hamburger from '../../assets/images/hamburger.png';
import Close from '../../assets/images/close.png';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 30px;
  z-index: 100;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 95px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0 65px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 0 150px;
  }
`;

const SocialWrapper = styled(Flex)`
  justify-content: space-between;
  padding: 30px 10px 15px;

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 30px 0 15px;
  }
`;

const SocialWrap = styled(Flex)`
  width: 140px;

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 175px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 270px;
  }
`;

const Link = styled(BaseLink)`
  line-height: 0;
`;

const Button = styled(BaseButton)`
  width: 100%;
  padding: 12px 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 280px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 200px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 270px;
    padding: 18px 0;
  }
`;

const NavsWrapper = styled(Flex)<{ isMobile?: boolean }>`
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: space-between;
  padding: 30px 0 0;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 9px 15px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0 135px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 0 250px;
  }
`;

const TabletWrapper = styled.div`
  padding: 30px 0 0;
`;

const MobileWrapper = styled(Flex)`
  justify-content: space-between;
`;

const MenuWrapper = styled.div<{ isMobile?: boolean }>`
  width: auto;
  margin-bottom: ${({ isMobile }) => (isMobile ? '30px' : '0')};
  cursor: pointer;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 60px 60px 90px;
  background: ${({ theme }) => theme.colors.primary};
  z-index: 100;
`;

const NavsLink = styled(BaseLink)`
  text-shadow: 2px 2px 4px #000000;

  &:hover:not(:disabled):not(:active) {
    text-decoration: underline;
  }
`;

const LogoImage = styled(Image)`
  width: 100%;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 354px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 450px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 424px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 500px;
  }
`;

const abbreviateAddress = (address = '', lengthStart = 5, lengthEnd = 5) => {
  return `${address.substring(0, lengthStart)}...${address.substring(
    address.length - lengthEnd,
    address.length,
  )}`;
};

const Header: FC = () => {
  const { isXxl } = useMatchBreakpoints();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const dispatch = useDispatch();
  const { account } = useSelector((state: RootState) => state);

  const [isDropdown, setIsDropdown] = useState(false);

  const connectHandler = useCallback(
    (e) => {
      e.preventDefault();
      setIsDropdown(false);
      dispatch(connect());
    },
    [dispatch],
  );

  const changeHandler = useCallback(() => {
    setIsDropdown(!isDropdown);
  }, [isDropdown]);

  const closeHandler = useCallback(() => {
    setIsDropdown(false);
  }, []);

  const NavsCard: FC = () => (
    <NavsWrapper isMobile={isMobile}>
      <NavsLink to="/" onClick={closeHandler}>
        Home
      </NavsLink>
      <NavsLink to="/game" onClick={closeHandler}>
        Game
      </NavsLink>
      <NavsLink to="/lore" onClick={closeHandler}>
        Lore
      </NavsLink>
      <NavsLink to="/account" onClick={closeHandler}>
        Account
      </NavsLink>
      <NavsLink to="/about" onClick={closeHandler}>
        About
      </NavsLink>
    </NavsWrapper>
  );

  return (
    <>
      <Container>
        {!isTablet && !isMobile ? (
          <>
            <SocialWrapper>
              <SocialWrap>
                <ExternalLink href="https://discord.gg/SNUfx429bU" target="_blank" mr="60px">
                  <Image
                    src={Discord}
                    width={isXxl ? 80 : 60}
                    height={isXxl ? 80 : 60}
                    alt="Discord"
                  />
                </ExternalLink>
                <ExternalLink href="https://mobile.twitter.com/antsyantsnft" target="_blank">
                  <Image
                    src={Twitter}
                    width={isXxl ? 80 : 60}
                    height={isXxl ? 80 : 60}
                    alt="Twitter"
                  />
                </ExternalLink>
              </SocialWrap>
              <Link to="/">
                <LogoImage src={Logo} alt="Logo" />
              </Link>
              <Button onClick={connectHandler}>
                {account ? abbreviateAddress(account) : 'Connect Wallet'}
              </Button>
            </SocialWrapper>
            <NavsCard />
          </>
        ) : (
          <TabletWrapper>
            <Link to="/" mb="15px">
              <LogoImage src={Logo} alt="Logo" />
            </Link>
            {!isMobile && <NavsCard />}
            <MobileWrapper>
              <SocialWrap>
                <ExternalLink
                  href="https://discord.com"
                  target="_blank"
                  mr={isMobile ? '20px' : '60px'}
                >
                  <Image src={Discord} width={60} height={60} alt="Discord" />
                </ExternalLink>
                <ExternalLink href="https://twitter.com" target="_blank">
                  <Image src={Twitter} width={60} height={60} alt="Twitter" />
                </ExternalLink>
              </SocialWrap>
              {!isMobile && (
                <Button onClick={connectHandler}>
                  {account ? abbreviateAddress(account) : 'Connect Wallet'}
                </Button>
              )}
              {isMobile && (
                <MenuWrapper onClick={changeHandler}>
                  <Image src={Hamburger} width={60} height={60} alt="Menu" />
                </MenuWrapper>
              )}
            </MobileWrapper>
          </TabletWrapper>
        )}
      </Container>
      {isDropdown && (
        <DropdownMenu>
          <MenuWrapper isMobile={isMobile} onClick={changeHandler}>
            <Image src={Close} width={60} height={60} alt="CloseIcon" />
          </MenuWrapper>
          <Button onClick={connectHandler}>
            {account ? abbreviateAddress(account) : 'Connect Wallet'}
          </Button>
          <NavsCard />
        </DropdownMenu>
      )}
    </>
  );
};

export default Header;
